import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import {
  FaFileCode,
  FaFileCsv,
  FaFileExcel,
  FaFileLines,
  FaFilePdf,
  FaFileVideo,
  FaFileWord,
} from "react-icons/fa6";
import { FaExternalLinkSquareAlt } from "react-icons/fa";

function ViewFileButtons({ hit }) {
  const links = [
    { type: "PDF File", icon: <FaFilePdf />, url: hit.pdf_url },
    { type: "Word File", icon: <FaFileWord />, url: hit.word_url },
    { type: "Excel File", icon: <FaFileExcel />, url: hit.xls_url },
    { type: "CSV File", icon: <FaFileCsv />, url: hit.csv_url },
    { type: "Text File", icon: <FaFileLines />, url: hit.txt_url },
    { type: "JSON File", icon: <FaFileCode />, url: hit.json_url },
    { type: "Video File", icon: <FaFileVideo />, url: hit.video_url },
    { type: "External Link", icon: <FaExternalLinkSquareAlt />, url: hit.external_link },
  ];

  return (
    <>
      {links.map(
        (link, i) =>
          link.url && (
            <Tooltip
              key={i}
              title={`View ${link.type}`}
              slotProps={{
                popper: { modifiers: [{ name: "offset", options: { offset: [0, -14] } }] },
              }}
            >
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  window.open(link.url, "_blank");
                }}
              >
                {link.icon}
              </IconButton>
            </Tooltip>
          )
      )}
    </>
  );
}

ViewFileButtons.propTypes = {
  hit: PropTypes.object,
};

export default ViewFileButtons;
