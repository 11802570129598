import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, useHits, RefinementList } from "react-instantsearch";
import StaticPageContainer from "../../components/static-page";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { FilterList } from "@mui/icons-material";
import { parseHtml } from "../../helpers/helper";
import CustomFilterNumber from "../../components/filter/filter-number";
import CustomClearFilterButton from "../../components/filter/clear-filter";
import CustomFilterWidget from "../../components/filter/filter-widget";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import "./faq.scss";
import PropTypes from "prop-types";

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

const primaryTextColor = "#5a5e9a";
const primaryBgColor = "#777aaf";
const backgroundColorLight = "#fbfbfb";
const backgroundColorDark = "#6c6fa8";
const filterbackgroundColor = "#f6f7fe";

const CustomHighlight = ({ attribute, hit }) => {
  const highlightValue = hit._highlightResult[attribute].value;
  const parsedHTML = parseHtml(highlightValue);

  return <>{parsedHTML}</>;
};

CustomHighlight.propTypes = {
  attribute: PropTypes.string,
  hit: PropTypes.object,
};

const CustomAccordion = styled(Accordion)(() => {
  return {
    boxShadow: "none",
    border: "none",
    "&::before": {
      display: "none",
    },
  };
});

const CustomHits = (props) => {
  const { results } = useHits(props);
  if (!results) return null;

  const { hits } = results;
  if (!hits.length) {
    return <Typography variant="h6">No results found</Typography>;
  }

  const groupedByFaqType = hits.reduce((acc, { typeName, typeDescription, ...rest }) => {
    if (!acc[typeName]) {
      acc[typeName] = {
        description: typeDescription,
        items: [],
      };
    }
    acc[typeName].items.push({ typeName, typeDescription, ...rest });
    return acc;
  }, {});

  return (
    <div>
      {Object.keys(groupedByFaqType).map((type) => (
        <div key={type} className="pb-5 mb-3">
          <h4 style={{ color: primaryTextColor }}>{type}</h4>
          <div className="fs-6">{parseHtml(groupedByFaqType[type].description)}</div>

          {groupedByFaqType[type].items.map((hit, i) => (
            <div key={i} className="py-1">
              <CustomAccordion
                // defaultExpanded={isSpecificHit}
                style={{ backgroundColor: backgroundColorLight, borderRadius: 10 }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    className="faq-text d-flex m-0"
                    style={{ color: primaryTextColor }}
                  >
                    <CustomHighlight attribute="question" hit={hit} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="d-flex pt-0">
                  <Typography variant="body1" gutterBottom className="d-flex m-0">
                    <CustomHighlight attribute="answer" hit={hit} />
                  </Typography>
                </AccordionDetails>
              </CustomAccordion>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

CustomHits.propTypes = {
  results: PropTypes.object,
};

const filterColumns = [
  {
    filterColumnName: "Filter By Division",
    refinementListComponent: <RefinementList attribute="typeName" className="long-filter-list" />,
  },
  {
    filterColumnName: "Filter By Division",
    refinementListComponent: <RefinementList attribute="divisionNames" />,
  },
];

function FAQ(props) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <StaticPageContainer title={"Frequently Asked Questions"}>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_FAQ_INDEX}
        routing={{
          stateMapping: singleIndex(`${process.env.REACT_APP_ALGOLIA_FAQ_INDEX}`),
        }}
      >
        {/* Search */}
        <div className="d-flex justify-content-center py-2">
          <SearchBox placeholder="Search FAQ" searchAsYouType className="col-12 col-lg-6" />
        </div>

        {/* Filter */}
        <div className="d-flex justify-content-center justify-content-sm-start column-gap-5 pt-2">
          <Button
            variant="text"
            startIcon={<FilterList />}
            endIcon={
              <CustomFilterNumber isFilterOpen={isFilterOpen} textColor={backgroundColorDark} {...props} />
            }
            onClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
            sx={{
              px: 1.5,
              color: isFilterOpen ? "white" : primaryTextColor,
              backgroundColor: isFilterOpen ? primaryBgColor : "white",
              "&:hover": {
                backgroundColor: isFilterOpen ? backgroundColorDark : backgroundColorLight,
              },
            }}
          >
            Filter
          </Button>
          <CustomClearFilterButton textColor={primaryTextColor} backgroundColor={backgroundColorLight} />
        </div>

        <CustomFilterWidget
          filterColumns={filterColumns}
          isFilterOpen={isFilterOpen}
          backgroundColor={filterbackgroundColor}
          {...props}
        />

        {/* Results */}
        <Grid container my={2} py={5}>
          <CustomHits {...props} />
        </Grid>
      </InstantSearch>
    </StaticPageContainer>
  );
}

FAQ.propTypes = {
  resultsState: PropTypes.object,
};

export default FAQ;
