import AlertService from "../../components/alert-service/alert-service";
import DivisionServices from "../../components/division-homepage/division-services";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import PropTypes from "prop-types";

function ControllerHome({ division }) {
  return (
    <div>
      <AlertService divisionCode={division.divisionAbbreviation} />
      <DivisionServices divisionAbbreviation={division.divisionAbbreviation} sectionTitle="Our Services" />
      <DivisionAboutBanner
        divisionAbbreviation={division.divisionAbbreviation}
        divisionTag={division.tag}
        linkTo="/controller/about"
      />
      <DivisionContact
        divisionAbbreviation={division.divisionAbbreviation}
        sectionTitle={"Contact Controller"}
      />
    </div>
  );
}

ControllerHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default ControllerHome;
