import React from "react";
import { useParallax } from "react-scroll-parallax";
import Alerts from "./alerts";
import BannerActionCard from "../action-card/link-action-card-sub";
import { HistoryOutlined, Paid, ReceiptLong } from "@mui/icons-material";
import { govHubSubPath } from '../../utils/utils.js';
import { useNavigate } from "react-router";

const Banner = () => {
  const welcome = useParallax({ scale: [1.15, 0.95, "easeInQuad"] });
  const alert = useParallax({ scale: [1, 1.1, "easeInQuad"] });
  const navigate = useNavigate();

  return (
    <div className="banner">
      <div className="banner-wrapper">
        <div className="welcome-atc container">
          <div ref={welcome.ref}>
            <div className="welcome">Welcome</div>
            <div className="atc">To the Auditor-Controller/ Treasurer/ Tax Collector</div>
          </div>
        </div>
        <div className="alerts container pb-5" ref={alert.ref}>
          <div className="row d-flex justify-content-center py-5">
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 p-3 my-2">
              <BannerActionCard
                title="Pay My Property Taxes"
                handleClick={() => {
                  //const { route, widget } = getWidgetConfig('pay-online')
                  //return navigate(route, { state: { config: widget } });
                  return navigate("/" + govHubSubPath + "/property-tax");
                }}
                icon={<Paid sx={{ fontSize: "60px", mb: 2 }} />}
              ></BannerActionCard>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 p-3 my-2">
              <BannerActionCard
                title="View My Property Tax Bill"
                handleClick={() => {
                  //const { route, widget } = getWidgetConfig('view-bill')
                  //  return navigate(route, { state: { config: widget } });
                  return navigate("/" + govHubSubPath + "/my-dashboard#my-items");
                }}
                icon={<ReceiptLong sx={{ fontSize: "60px", mb: 2 }} />}
              ></BannerActionCard>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 p-3 my-2">
              <BannerActionCard
                title="View My Payment History"
                handleClick={() => {
                  //const { route, widget } = getWidgetConfig('view-payment-history')
                  //  return navigate(route, { state: { config: widget } });
                  return navigate("/" + govHubSubPath + "/my-dashboard#my-payments");
                }}
                icon={<HistoryOutlined sx={{ fontSize: "60px", mb: 2 }} />}
              ></BannerActionCard>
            </div>
          </div>

          <div className="pt-3 pb-5 py-md-5">
            <Alerts></Alerts>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
