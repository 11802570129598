import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { Button, Grid, Paper, Typography } from '@mui/material';


const categoryId = process.env.REACT_APP_GOVDELIVERY_CATEGORY_ID;
export function GovDelivery() {
    const [subscription_type, setSubscription_type] = useState('email');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [input, setInput] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    }

    const handleSubmit = (event) => {
        setShowAlert(false);
        if (validateEmail(input)) {
            setSubscription_type('email');
            setEmail(input);

        }
        else if (validatePhone(input)) {
            setSubscription_type('phone');
            setPhone(input);

        }
        else {
            event.preventDefault();
            setShowAlert(true);
            return false;
        }
    }
    const validateEmail = (email) => {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }
    const validatePhone = (phone) => {
        var re = /^(?:\+1)?(?:\s|-)?\(?(\d{3})\)?(?:\s|-)?(\d{3})(?:\s|-)?(\d{4})$/;
        return re.test(String(phone).toLowerCase());
    }

    return (
        <div className='stay-connected-wrapper  justify-content-around align-items-center'>

            <Grid container spacing={2} style={{ padding: '100px' }}>
                <Grid item xs={12} sm={1} md={2} xl={3}>
                </Grid>
                <Grid item xs={12} sm={10} md={8} xl={6}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{ paddingTop: '0px' }}>
                                <div className='h-100 d-flex justify-content-center align-items-center' style={{ backgroundColor: 'aliceblue' }} >
                                    <img className='paper-plane' src="/assets/images/misc/Paper_Plane_Vector.svg" />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={8} className='p-5'>
                                <Typography variant="h5" component="h1" gutterBottom>
                                    Stay Connected</Typography>
                                <div className='d-flex flex-column align-items-left mb-4'>
                                    <Typography variant='body' gutterBottom className='mb-1'>
                                        Please use the form below to setup new e-mail/SMS subscriptions, manage your existing subscriptions, or have your subscription information sent to you.
                                    </Typography>
                                    <Typography variant='body' gutterBottom className='mb-1'>
                                        To sign up for updates or to access your subscriber preferences, please enter your contact information below.
                                    </Typography>
                                </div>


                                <div className="">
                                    <form id="GD-snippet-form" action="https://public.govdelivery.com/accounts/CASANBE/subscribers/qualify" acceptCharset="UTF-8" method="post" onSubmit={handleSubmit} target="_blank">
                                        <input name="utf8" type="hidden" value="&#x2713;" />
                                        <input type="hidden" name="category_id" id="category_id" value={categoryId} />
                                        <input type="hidden" name="subscription_type" id="subscription_type" value={subscription_type} />
                                        <input type="hidden" name="country_code_display" id="country_code_display" value="1 (US)" />
                                        <input type="hidden" name="email" id="email" value={email} />
                                        <input type="hidden" name="phone" id="phone" value={phone} />


                                        <div className='d-flex'>
                                            <TextField
                                                label="Enter email address or mobile number for texting."
                                                variant="outlined"
                                                fullWidth
                                                onChange={handleInputChange}
                                                value={input}
                                                sx={{ height: '56px' }}
                                            />
                                            <Button variant="contained" type="submit" style={{ height: '56px' }}>Subscribe</Button>

                                        </div>

                                        {showAlert && <Alert severity="error" >Please enter valid email address or US mobile number.</Alert>}

                                    </form>
                                </div>
                                <Typography variant='caption' gutterBottom className='mt-2'>
                                    Your e-mail and phone number information will never be used to send information that you have not requested or shared with any other entity for marketing purposes.
                                </Typography>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={1} md={2} xl={3}>
                </Grid>

            </Grid>

        </div>
    );
}
