import React from "react";
import DivisionServices from "../../components/division-homepage/division-services";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import AlertService from "../../components/alert-service/alert-service";
import PropTypes from "prop-types";

function ITHome({ division }) {
  return (
    <>
      <AlertService divisionCode={division.divisionAbbreviation} />
      <DivisionServices divisionAbbreviation={division.divisionAbbreviation} sectionTitle="Our Services" />
      <DivisionAboutBanner
        divisionAbbreviation={division.divisionAbbreviation}
        divisionTag={division.tag}
        linkTo="/information-technology/about"
      />
      <DivisionContact
        divisionAbbreviation={division.divisionAbbreviation}
        sectionTitle={"Contact ITD"}
        formLink="/"
      />
    </>
  );
}

ITHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default ITHome;
