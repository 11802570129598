import React, { useEffect } from 'react';
import { getDataFromCmsApi } from '../../../api/contentful-service';
import { Avatar, Box } from '@mui/material';
import 'animate.css';
import './meet-the-team.scss';
import { useNavigate } from 'react-router-dom'; // For redirection

function MeetTheTeamLink() {
    const [team, setTeam] = React.useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch team data from an API or CMS
        const fetchTeamData = async () => {
            let response = await getDataFromCmsApi("TeamMember"); // Replace with your API URL    
            console.log(response);
            setTeam(response);
        }
        fetchTeamData();
    }, []);

    const handleClick = () => {
        // Redirect to the "Meet the Team" page
        navigate('exec-team');
    };

    return (
        <Box className="team-container" onClick={handleClick}>
            <div className="meet-the-team team-flip-card" key="meetteam" style={{ zIndex: 100 }}>
                <div className="team-flip-card-inner">
                    {/* Front of the card */}
                    <div className="team-flip-card-front">
                        <Avatar
                            alt="Meet the team link"
                            src="./assets/images/team/meet-the-team.png"
                            className="meet-the-team-avatar"
                        />
                    </div>
                    {/* Back of the card */}
                    <div className="team-flip-card-back">
                        <Avatar
                            alt="Meet the team"
                            src="./assets/images/team/view-team-info.png" // You can replace with another image for the flip
                            className="meet-the-team-avatar"
                        />
                    </div>
                </div>
            </div>

            {team && team.map((member, index) => (
                <div className="team-member" key={member.id} style={{ zIndex: 10 - index }}>
                    <Avatar
                        alt={member.position}
                        src={member.imageUrl}
                        className='team-member-avatar'
                    />
                </div>
            ))}
        </Box>
    );
}

export default MeetTheTeamLink;
