import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaXTwitter, FaYelp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Link as MuiLink } from '@mui/material';
import { govHubSubPath } from "../utils/utils.js";


const divisions = [
    { name: "Controller", path: "/controller" },
    { name: "Disbursements", path: "/disbursements" },
    { name: "Information Technology", path: "/information-technology" },
    { name: "Internal Audits", path: "/internal-audits" },
    { name: "Revenue Recovery", path: "/revenue-recovery" },
    { name: "SAP Center of Excellence", path: "/sap-center-of-excellence" },
    { name: "Treasurer", path: "/treasurer" },
    { name: "Property Tax", path: "/property-tax" },
    { name: "Tax Collector", path: "/tax-collector" },
];


divisions.sort((a, b) => a.name.localeCompare(b.name));



const Footer = () => {
    return (
        <footer className="footer mt-auto mx-auto py-3">
            <div className="d-flex justify-content-center">
                <div className="d-md-flex justify-content-center my-4">
                    <img
                        className="footer-logo img-fluid mb-4"
                        src="/assets/logos/sb-logo-full-color.png"
                        alt="San Bernardino County Logo"
                    ></img>
                    <svg className="footer-separator d-none d-md-block" width="90" height="200">
                        <line x1="20" y1="0" x2="20" y2="200"></line>
                    </svg>
                    <div className="">
                        <img
                            className="footer-atc img-fluid mb-4"
                            src="/assets/images/department-name.png"
                            alt="Auditor-Controller/Treasurer/Tax Collector"
                        ></img>
                        <div className="notranslate">
                            <p className="lead mb-0 text-light">268 W Hospitality Lane</p>
                            <p className="lead mb-0 text-light">San Bernardino, CA 92415</p>
                            <p className="lead mb-0 text-light">(909) 387-8308</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='d-flex justify-content-center my-4'>
                <div className=''>
                    <h4 className='text-secondary upper-case'>STAY CONNECTED</h4>
                    <div className='d-flex'>
                        <input className='email' placeholder='ENTER YOUR EMAIL ADDRESS'></input>
                        <button type='button' className='btn btn-primary btn-md'>SUBMIT</button>
                    </div>
                </div>
            </div> */}
            <div className="container fluid text-light my-4">
                <div className="row">
                    <ul className="col-6 col-md-2 list-unstyled">
                        <li className="h6">
                            <b>Tax Collector</b>
                        </li>
                        <li>
                            <Link to="/tax-collector/important-dates">Important Dates</Link>
                        </li>
                        <li>
                            <Link to={"/" + govHubSubPath + "/property-tax"}>Pay Your Tax Bill</Link>
                        </li>
                        <li>TOT</li>
                        <li>
                            <Link to={"/" + govHubSubPath + "/property-tax/forms/penalty-waiver-request"}>Penalty Cancellation Form</Link>
                        </li>
                        <li>
                            <Link to="/tax-collector/tax-sale">Tax Sale</Link>
                        </li>
                    </ul>
                    <ul className=" col-6 col-md-2 list-unstyled">
                        <li className="h6">
                            <b>Property Tax</b>
                        </li>
                        <li>Where do my Property Tax Dollars Go?</li>
                        <li>
                            <Link to={"/" + govHubSubPath + "/property-tax"}>Unclaimed Property Tax Refund</Link>
                        </li>
                        {/*<li>Annual Breakdown</li>*/}
                        <li>
                            <Link to="search/documents?divisionName=Property Tax">Reports and Publications</Link>
                        </li>
                    </ul>
                    <ul className="col-6 col-md-2 list-unstyled">
                        <li className="h6">
                            <b>Divisions</b>
                        </li>
                        {divisions.map((division) => (
                            <li key={division.path}>
                                <Link to={division.path}>{division.name}</Link>
                            </li>
                        ))}
                    </ul>
                    <ul className=" col-6 col-md-2 list-unstyled">
                        <li className="h6">
                            <b>General</b>
                        </li>
                        <li>
                            <Link to="/about">About ATC</Link>
                        </li>
                        <li>
                            <Link to="/awards-and-accolades">Awards and Accolades</Link>
                        </li>
                        <li>
                            <Link to="/hours-and-locations">Hours and Locations</Link>
                        </li>
                        <li>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="/news">News</Link>
                        </li>
                        <li>
                            <Link to="/newsletters">Newsletters</Link>
                        </li>
                        <li>
                            <a href="https://www.governmentjobs.com/careers/sanbernardino" target="_blank" rel="noreferrer">
                                Careers
                            </a>
                        </li>
                    </ul>
                    <ul className=" col-6 col-md-2 list-unstyled">
                        <li className="h6">
                            <b>Online Services</b>
                        </li>
                        <li>
                            <Link to={"/" + govHubSubPath + "/property-tax"}>Pay Your Tax Bill</Link>
                        </li>
                        <li>Online Documents</li>
                        <li>
                            <a href="https://countybillpay.com/" target="_blank" rel="noreferrer">
                                CountyBillPay.com
                            </a>
                        </li>
                        <li>Get Electronic Tax Bill</li>
                    </ul>
                    <ul className=" col-6 col-md-2 list-unstyled">
                        <li className="h6">
                            <b>Useful Links</b>
                        </li>
                        <li>
                            <a href="https://arc.sbcounty.gov/" target="_blank" rel="noreferrer">
                                Assessor-Recorder
                            </a>
                        </li>
                        <li>
                            <a href="https://sbcountyda.org/" target="_blank" rel="noreferrer">
                                District Attorney
                            </a>
                        </li>
                        <li>
                            <a href="https://wp.sbcounty.gov/sheriff/" target="_blank" rel="noreferrer">
                                Sheriff
                            </a>
                        </li>
                        <li>Report Fraud and Waste</li>
                        <li>
                            <a href="https://main.sbcounty.gov/about-bos/" target="_blank" rel="noreferrer">
                                Board of Supervisors
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center my-4">
                <a href="https://www.facebook.com/sanbernardinocountyatc/" target="_blank" rel="noreferrer">
                    <FaFacebook className="text-secondary m-2 social-icons" />
                </a>
                <a href="https://mobile.twitter.com/atc_san" target="_blank" rel="noreferrer">
                    <FaXTwitter className="text-secondary m-2 social-icons" />
                </a>
                <a href="https://www.instagram.com/sanbernardinocountyatc/" target="_blank" rel="noreferrer">
                    <FaInstagram className="text-secondary m-2 social-icons" />
                </a>
                <a
                    href="https://www.yelp.com/biz/san-bernardino-county-tax-collector-san-bernardino-2?osq=San+Bernardino+County+Tax+Collector"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FaYelp className="text-secondary m-2 social-icons" />
                </a>
                <a href="https://www.linkedin.com/company/san-bernardino-county-atc" target="_blank" rel="noreferrer">
                    <FaLinkedin className="text-secondary m-2 social-icons" />
                </a>
            </div>
            <div className="d-flex justify-content-center text-light upper-case px-2 text-center">
                <MuiLink href="/tax-collector/privacy-policy" color="#FFFFFF" className="mx-1" ><small>Privacy Policy</small></MuiLink> |
                <MuiLink href='/privacy-settings' color="#FFFFFF" className="mx-1"><small>Privacy Settings</small></MuiLink>
            </div>
            <div className="d-flex justify-content-center text-light upper-case px-2 text-center">
                <small>
                    {new Date().getFullYear()} San Bernardino County Auditor Controller/Treasurer/tax collector - all rights reserved
                </small>
            </div>
        </footer >
    );
};

export default Footer;
