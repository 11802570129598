import React, { useEffect, useState } from "react";
import {
    Alert,
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    Grid
} from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import TaxCollectorActionCard from "../../components/action-card/link-action-card";
import AlertService from "../../components/alert-service/alert-service";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
// import { govHubSubPath } from "../../utils/utils";
import { Link } from "react-router-dom";
import { isExternalLink } from "../../helpers/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import './index.scss';
import PropTypes from "prop-types";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const currentMonthIndex = new Date().getMonth();
const feeScheduleContentfulId = "tax_collector_fee_schedule";

const Home = ({ division }) => {
    const [atcHoursOfOperation, setatcHoursOfOperation] = useState();
    const [importantDates, setImportantDates] = useState();
    const [services, setServices] = useState();
    const [linkList, setLinkList] = useState([]);

    useEffect(() => {
        getHours();
        getDataImportantDates();
        getServices();
        getData();
    }, []);

    const getHours = async () => {
        let result = await getDataFromCmsApi("atcHoursOfOperation");
        result = result.filter((item) => {
            return item.divisionAbbreviation === division.divisionAbbreviation;
        });
        setatcHoursOfOperation(result[0]);
    };

    const getDataImportantDates = async () => {
        const result = await getDataFromCmsApi("ImportantDate");
        result.map((item) => {
            item.serviceDescription = parseHtml(item.serviceDescription);
            return item;
        });
        setImportantDates(result);
    };

    const getServices = async () => {
        const result = await getDataFromCmsApi(`DivisionService/${division.divisionAbbreviation}`);
        const sortedAndFilteredServices = result
            .filter(service => !service.isMoreServices)
            .sort((a, b) => a.serviceLabel.localeCompare(b.serviceLabel));
        setServices(sortedAndFilteredServices);
    };

    const getData = async () => {
        const divisionServiceResult = await getDataFromCmsApi("divisionService");
        const filteredResult = divisionServiceResult.filter(item => item.isMoreServices === true);
        const linkList = filteredResult
            .map(item => ({
                linkTitle: item.serviceLabel,
                linkDestination: item.link
            }))
            .sort((a, b) => a.linkTitle.localeCompare(b.linkTitle));
        setLinkList(linkList);
    };

    const ImportantDates = (monthIndex) => {
        return (
            <>
                {importantDates && importantDates
                    .filter((row) => months.indexOf(row.month) === monthIndex)
                    .map((row, i) => (
                        <ListItem key={i} className="py-0">
                            <ListItemAvatar className="mb-3">
                                <Avatar>
                                    <CalendarMonth />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${row.month} ${row.day !== 0 ? row.day : ""} `}
                                secondary={parseHtml(row.serviceDescription)}
                            />
                        </ListItem>
                    ))}
            </>
        );
    };

    return (
        <>
            <div className="container">
                {/* Alert */}
                <AlertService divisionCode={division.divisionAbbreviation} />

                {/* Services */}
                <div className="grid-container grid-style-4 py-5">
                    {services &&
                        services.map((item, index) => (
                            <TaxCollectorActionCard
                                key={index}
                                title={item.serviceLabel}
                                linkDestination={item.link}
                                cardColor={item.color}
                            />
                        ))}
                </div>

                {/* More Services */}
                <Box className="py-4">
                    <Typography variant="h5" className="px-3 px-md-0">
                        More Services...
                    </Typography>
                    {/* <DivisionLinks linkList={linkList} className="testing" /> */}
                    <Grid container spacing={12}>
                        <Grid item xs={4} lg={4}>
                            <List>
                                {linkList.slice(0, Math.ceil(linkList.length / 3)).map((link, i) => (
                                    <ListItemButton
                                        key={i}
                                        className="d-flex justify-content-between"
                                        divider
                                        component={Link}
                                        to={link.linkDestination}
                                        target={isExternalLink(link.linkDestination) ? "_blank" : ""}
                                        rel={isExternalLink(link.linkDestination) ? "noopener noreferrer" : ""}
                                    >
                                        <img src='/assets/images/nav/sb-county-shield.png' width={'5%'} alt="county-shield" />
                                        {link.linkTitle}
                                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={4}>
                            <List>
                                {linkList.slice(Math.ceil(linkList.length / 3), Math.ceil(linkList.length / 3) * 2).map((link, i) => (
                                    <ListItemButton
                                        key={i}
                                        className="d-flex justify-content-between"
                                        divider
                                        component={Link}
                                        to={link.linkDestination}
                                        target={isExternalLink(link.linkDestination) ? "_blank" : ""}
                                        rel={isExternalLink(link.linkDestination) ? "noopener noreferrer" : ""}
                                    >
                                        <img src='/assets/images/nav/sb-county-shield.png' width={'5%'} />
                                        {link.linkTitle}
                                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={4}>
                            <List>
                                {linkList.slice(Math.ceil(linkList.length / 3) * 2, linkList.length).map((link, i) => (
                                    <ListItemButton
                                        key={i}
                                        className="d-flex justify-content-between"
                                        divider
                                        component={Link}
                                        to={link.linkDestination}
                                        target={isExternalLink(link.linkDestination) ? "_blank" : ""}
                                        rel={isExternalLink(link.linkDestination) ? "noopener noreferrer" : ""}
                                    >
                                        <img src='/assets/images/nav/sb-county-shield.png' width={'5%'} alt="county-shield" />
                                        {link.linkTitle}
                                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            {/* About */}
            <div className="py-5">
                <DivisionAboutBanner
                    divisionAbbreviation={division.divisionAbbreviation}
                    divisionTag={division.tag}
                    linkTo='/tax-collector/about'
                />
            </div>

            <div className="container">
                {/* Important Dates */}
                <div className="py-5">
                    <Typography variant="h5">Upcoming Important Dates</Typography>
                    <List>
                        {importantDates && ImportantDates(currentMonthIndex)}
                        {importantDates && ImportantDates(currentMonthIndex + 1)}
                    </List>
                    <Button variant="contained" color="primary" href="tax-collector/important-dates">
                        View More Dates
                    </Button>
                </div>

                {/* Location */}
                <div className="pt-5">
                    <Typography variant="h5" className="px-3 mb-3 px-md-0">
                        Tax Collector Office Location
                    </Typography>
                    <div className="d-flex justify-content-center align-content-left">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.1153994877436!2d-117.29152782283867!3d34.06655591697222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcad8248e6ba13%3A0x98750ba7c33ecac6!2sSan%20Bernardino%20County%20ATC!5e0!3m2!1sen!2sus!4v1681325129103!5m2!1sen!2sus"
                            width="100%"
                            height="390"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            title="ATC Video"
                        ></iframe>
                    </div>
                </div>

                {/* Contact */}
                <DivisionContact
                    divisionAbbreviation={division.divisionAbbreviation}
                    sectionTitle={`Contact ${division.title}`}
                />
            </div>
        </>
    );
};

Home.propTypes = {
    division: PropTypes.object.isRequired,
};

export default Home;